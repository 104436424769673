<template>
  <b-card
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title class="my-auto">
        Funding indicators
      </b-card-title>
      <b-button
        v-b-tooltip.hover.top="'Force reload statistics'"
        class="d-block mx-1 float-right ml-auto"
        variant="outline-primary"
        size="sm"
        @click="onForceReload"
      >
        <feather-icon icon="RepeatIcon" />
      </b-button>

      <b-button-toolbar class="mr-1 my-auto">
        <account-types-switches
          v-model="accountTypes"
          :types="optionTypes"
          class="my-50"
        />

        <platforms-switches
          v-model="optionPlatforms"
          class="my-50"
        />
      </b-button-toolbar>
    </b-card-header>

    <b-overlay
      :show="loading"
      :variant="skin"
      spinner-variant="primary"
    >
      <b-card-body>
        <div
          class="transaction-item"
        >
          <b-media no-body>
            <b-media-aside>
              <b-avatar
                rounded
                size="42"
                variant="light-info"
              >
                <BarChart2Icon
                  size="18"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="transaction-title">
                Funding rate 2-phase
              </h6>
              <small>Percentage chance to get Funded account</small>
            </b-media-body>
          </b-media>
          <div
            class="font-weight-bolder"
          >
            {{ formatNumber(rate) }}%
          </div>
        </div>
        <div
          class="transaction-item"
        >
          <b-media no-body>
            <b-media-aside>
              <b-avatar
                rounded
                size="42"
                variant="light-info"
              >
                <BarChart2Icon
                  size="18"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="transaction-title">
                Funding rate 3-phase
              </h6>
              <small>Percentage chance to get Funded account</small>
            </b-media-body>
          </b-media>
          <div
            class="font-weight-bolder"
          >
            {{ formatNumber(rate3) }}%
          </div>
        </div>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BMediaBody,
  BAvatar,
  BMedia,
  BMediaAside,
  BButtonToolbar,
  BOverlay,
  BButton, VBTooltip,
} from 'bootstrap-vue'
import {
  BarChart2Icon,
} from 'vue-feather-icons'
import { get } from 'vuex-pathify'
import { formatNumber } from '@/plugins/formaters'
import StatisticsAPI from '@/api/statistics'
import AccountTypesSwitches from '@/components/ui/switches/AccountTypesSwitches.vue'
import PlatformsSwitches from '@/components/ui/switches/PlatformsSwitches.vue'
import { DEFAULT_PLATFORMS_SWITCHER, THREE_PHASE_ACCOUNTS, TWO_PHASE_ACCOUNT_TYPES_SWITCHER } from '@/enums/accounts'

export default {
  components: {
    PlatformsSwitches,
    AccountTypesSwitches,
    BButton,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BAvatar,
    BMedia,
    BMediaBody,
    BMediaAside,
    BButtonToolbar,
    BOverlay,
    BarChart2Icon,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      accountTypes: TWO_PHASE_ACCOUNT_TYPES_SWITCHER.map(item => item.value),
      platforms: DEFAULT_PLATFORMS_SWITCHER,
      rate: null,
      loading: true,
      optionTypes: TWO_PHASE_ACCOUNT_TYPES_SWITCHER,
      optionPlatforms: DEFAULT_PLATFORMS_SWITCHER,
      rate3: null,
    }
  },
  computed: {
    ...get('appConfig@layout', ['skin']),
  },
  watch: {
    accountTypes() {
      this.onGetFundingRate(false)
    },
    platforms() {
      this.onGetFundingRate(false)
    },
  },
  beforeMount() {
    this.getFundingRateTrack(false)
    this.onGetFundingRate(false)
  },
  methods: {
    getFundingRateTrack(force = false) {
      this.loading = true
      StatisticsAPI.getFundingRate(THREE_PHASE_ACCOUNTS, ['MT4', 'MT5'], force).then(response => {
        this.rate3 = response
      }).finally(() => {
        this.loading = false
      })
    },
    onGetFundingRate(force = false) {
      this.loading = true
      StatisticsAPI.getFundingRate(this.accountTypes, this.platforms, force)
        .then(response => {
          this.rate = response
        }).finally(() => {
          this.loading = false
        })
    },
    onForceReload() {
      this.getFundingRateTrack(true)
      this.onGetFundingRate(true)
    },
    formatNumber,
  },
}
</script>
