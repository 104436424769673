<template>
  <b-form-checkbox-group
    :checked="value"
    :options="optionTypes"
    switches
    @input="e => $emit('input', e)"
  />
</template>
<script>
import { BFormCheckboxGroup } from 'bootstrap-vue'
import { DEFAULT_PLATFORMS_SWITCHER } from '@/enums/accounts'

export default {
  components: {
    BFormCheckboxGroup,
  },
  props: {
    value: Array,
  },
  data() {
    return {
      optionTypes: DEFAULT_PLATFORMS_SWITCHER,
    }
  },
}
</script>
