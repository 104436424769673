<template>
  <b-overlay
    :show="!profits"
    :variant="skin"
    spinner-variant="primary"
  >
    <b-card no-body>
      <b-card-header>
        <b-card-title>Funded profits by tags</b-card-title>
        <small>Sum of profit of each currency from funded accounts with selected tags</small>
      </b-card-header>
      <b-form-group
        v-if="tags"
        class="m-1"
        label="Tags"
      >
        <b-badge
          v-for="(item, index) in tags"
          :key="index"
          class="m-50"
          :variant="item.color"
        >
          {{ item.name }}
          <b-button
            class="btn-icon rounded-circle"
            size="sm"
            :variant="item.color"
            @click="removeTag(item.id)"
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </b-badge>
        <custom-select
          module-name="tagsList"
          label="name"
          not-reduce-by-id
          @input="addTag"
        />
      </b-form-group>

      <b-card-body
        v-if="profits && profits.length > 0"
        class="position-relative"
      >
        <vue-apex-charts
          type="bar"
          height="100"
          :options="generateChartConfig(themeColors.success)"
          :series="profitAccounts"
        />
      </b-card-body>
      <b-card v-else>
        There is no funded profit on account with selected tags
      </b-card>
    </b-card>
  </b-overlay>
</template>
<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BOverlay, BFormGroup, BBadge, BButton,
} from 'bootstrap-vue'
import { get } from 'vuex-pathify'
import VueApexCharts from 'vue-apexcharts'
import CustomSelect from '@/components/ui/forms/CustomSelect.vue'
import { $themeColors } from '@/../themeConfig'
import { formatNumber } from '@/plugins/formaters'
import StatisticsAPI from '@/api/statistics'

export default {
  components: {
    VueApexCharts,
    CustomSelect,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BOverlay,
    BFormGroup,
    BBadge,
    BButton,
  },
  data() {
    return {
      profits: null,
      tags: [],
    }
  },
  computed: {
    ...get('appConfig@layout', ['skin']),
    themeColors() {
      return $themeColors
    },
    profitAccounts() {
      const acc = []
      this.profits.forEach(item => {
        acc.push(
          {
            y: item.amount,
            x: item.label,
          },
        )
      })

      return [{
        name: 'Profit',
        data: acc,
      }]
    },
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    generateChartConfig(color) {
      return {
        chart: {
          type: 'bar',
        },
        grid: {
          padding: {
            top: -20,
            bottom: -10,
          },
          yaxis: {
            lines: { show: true },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: 30,
          style: {
            fontSize: '12px',
            colors: ['#fff'],
          },
          formatter: value => formatNumber(value),
        },
        xaxis: {
          type: 'category',
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '1rem',
            },
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
        legend: {
          show: false,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: 'top',
            },
          },
        },
        colors: [color],
        fill: {
          opacity: 0.5,
          colors: [color],
        },
        stroke: {
          show: true,
          colors: [$themeColors.primary, $themeColors.info],
          width: 1,
        },
        yaxis: {
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '1.5rem',
            },
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
      }
    },
    addTag(item) {
      if (!this.tags.find(tag => tag.id === item.id)) {
        this.tags.push(item)
        this.loadData()
      }
    },
    removeTag(id) {
      this.tags = this.tags.filter(item => item.id !== id)
      this.loadData()
    },
    loadData() {
      this.profits = null
      const tagIds = this.tags.map(tag => tag.id)
      StatisticsAPI.getFundedProfitsStatisticsByTags(tagIds).then(response => {
        this.profits = response
      })
    },
  },
}
</script>
