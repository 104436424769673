<template>
  <div>
    <gql-table
      ref="usersTable"
      :fields="fields"
      :query="query"
      query-type="users"
      :sort-desc="true"
      :per-page-max="1"
      :actions="actions"
      @loginAs="onLoginAs"
    >
      <template #cell(full_name)="data">
        <b-link :to="{name: 'user-detail-ext', params: {id: data.item.id}}">
          {{ data.item.firstName }} {{ data.item.lastName }}
        </b-link>
      </template>

      <template #cell(userEmail)="data">
        {{ data.item.email }}
      </template>

    </gql-table>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { BLink } from 'bootstrap-vue'
import UserAPI from '@/api/user'
import GqlTable from '@/components/ui/gqltables/GqlTable.vue'

const fields = [
  {
    name: 'full_name',
    label: 'Full name',
  },
  {
    name: 'firstName', label: 'First name', visible: false,
  },
  {
    name: 'lastName', label: 'Last name', visible: false,
  },
  {
    name: 'userEmail',
    label: 'Email',
    filterable: 'eq',
    sortable: true,
  },
  {
    name: 'actions',
  },
]

const query = [
  'firstName',
  'lastName',
  'id',
  'email',
]

const actions = [
  {
    text: 'Login as user',
    icon: 'KeyIcon',
    emit: 'loginAs',
  },
]

export default {
  components: {
    BLink,
    GqlTable,
  },
  data() {
    return {
      fields,
      query,
      actions,
    }
  },
  methods: {
    onLoginAs(item) {
      UserAPI.postLoginAs(item.id)
        .then(response => {
          Swal.fire({
            title: 'Login as user',
            html: `<p>Here is user login link: (link is valid for 2 minutes)</p><a target="_blank" href="${response}">${response}</a>`,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok',
          })
        })
    },
  },
}
</script>
