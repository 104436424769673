<template>
  <b-overlay
    :show="!accountsSum || loading"
    :variant="skin"
    spinner-variant="primary"
  >
    <b-card
      v-if="accountsSum"
      no-body
    >
      <b-card-header>
        <b-card-title>Phases analysis {{ phases }}-phase</b-card-title>
        <b-button
          v-b-tooltip.hover.top="'Force reload statistics'"
          class="float-right"
          variant="outline-primary"
          size="sm"
          @click="$emit('force-reload')"
        >
          <feather-icon icon="RepeatIcon" />
        </b-button>
      </b-card-header>

      <b-card-body class="position-relative">
        <vue-apex-charts
          type="bar"
          height="250"
          :options="phasesChart.chartOptions"
          :series="phasesChart.series"
        />
      </b-card-body>
    </b-card>
  </b-overlay>
</template>
<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BOverlay, BButton, VBTooltip,
} from 'bootstrap-vue'
import { get } from 'vuex-pathify'
import _groupBy from 'lodash/groupBy'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@/../themeConfig'

export default {
  components: {
    BButton,
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    accountsSum: Array,
    phases: Number,
    loading: Boolean,
  },
  computed: {
    ...get('appConfig@layout', ['skin']),
    chartData() {
      const byState = _groupBy(this.accountsSum, result => result.state)
      const keys = Object.keys(byState)
      keys.forEach(key => {
        byState[key] = _groupBy(byState[key], result => result.account_subtype_name)
      })

      return Object.keys(byState).map(key => ({
        name: key,
        data: [
          byState[key].phase1 ? byState[key].phase1[0].count : 0,
          byState[key].phase2 ? byState[key].phase2[0].count : 0,
          // eslint-disable-next-line no-nested-ternary
          ...(this.phases === 3 ? [(byState[key].phase3 ? byState[key].phase3[0].count : 0)] : []),
          byState[key].funded ? byState[key].funded[0].count : 0,
        ],
      }))
    },
    phasesChart() {
      return {
        series: this.chartData,
        chartOptions: {
          chart: {
            stacked: true,
            type: 'bar',
            toolbar: { show: true },
          },
          grid: {
            padding: {
              top: -20,
              bottom: -10,
            },
            yaxis: {
              lines: { show: true },
            },
          },
          xaxis: {
            categories: ['Phase1', 'Phase2', ...(this.phases === 3 ? ['Phase3'] : []), 'Funded'],
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: [$themeColors.success, $themeColors.primary, $themeColors.danger],
          fill: {
            opacity: 0.5,
            colors: [$themeColors.success, $themeColors.primary, $themeColors.danger],
          },
          yaxis: {
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '0.7rem',
              },
            },
          },
          tooltip: {
            shared: true,
            intersect: false,
            x: { show: true },
            y: { show: true },
          },
        },
      }
    },
  },
}
</script>
