<template>
  <b-overlay
    :show="loading"
    :variant="skin"
    spinner-variant="primary"
  >
    <b-card
      class="card-transaction"
      no-body
    >
      <b-card-header>
        <b-card-title>Orders data</b-card-title>
        <b-button
          v-b-tooltip.hover.top="'Force reload statistics'"
          class="float-right"
          variant="outline-primary"
          size="sm"
          @click="$emit('force-reload')"
        >
          <feather-icon icon="RepeatIcon" />
        </b-button>
      </b-card-header>

      <b-card-body>
        <div
          v-for="(item, key, index) in ACCOUNTS"
          :key="index"
          class="transaction-item"
        >
          <b-media no-body>
            <b-media-aside>
              <b-avatar
                rounded
                size="35"
                variant="light-info"
              >
                <feather-icon
                  :icon="item.icon"
                  size="18"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="transaction-title">
                {{ item.title }}
              </h6>
            </b-media-body>
          </b-media>
          <div
            class="font-weight-bolder"
          >
            <b-row>
              <span class="ml-auto">{{ getOrderCountByAccountType(key).count }}</span>
            </b-row>
            <b-row>
              <small class="ml-auto">
                {{ formatCurrency(getOrderCountByAccountType(key).profit, 'USD') }}
              </small>
            </b-row>
          </div>
        </div>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BAvatar,
  BMedia,
  BMediaAside,
  BMediaBody,
  BRow,
  BOverlay,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import { get } from 'vuex-pathify'
import { formatCurrency, formatDate } from '@/plugins/formaters'
import { ACCOUNTS } from '@/enums/accounts'

export default {
  components: {
    BButton,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BAvatar,
    BMedia,
    BMediaBody,
    BMediaAside,
    BRow,
    BOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    orders: Array,
    loading: Boolean,
  },
  data() {
    return {
      ACCOUNTS,
    }
  },
  computed: {
    ...get('appConfig@layout', ['skin']),
  },
  methods: {
    formatCurrency,
    formatDate,
    getOrderCountByAccountType(type) {
      return this.orders.find(item => item.account_type === type) ?? { count: 0, profit: 0 }
    },
  },
}
</script>
