<template>
  <b-card
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Quick statistics</b-card-title>
      <b-button
        v-b-tooltip.hover.top="'Force reload statistics'"
        class="float-right"
        variant="outline-primary"
        size="sm"
        @click="$emit('force-reload')"
      >
        <feather-icon icon="RepeatIcon" />
      </b-button>
    </b-card-header>
    <b-overlay
      :show="loading"
      :variant="skin"
      spinner-variant="primary"
    >
      <b-card-body
        v-if="statistics"
        class="statistics-body"
      >
        <b-row>
          <b-col
            v-for="item in statistics_items"
            :key="item.name"
            v-b-tooltip.hover.bottom="item.tooltip"
            class="mt-1"
            xxl="3"
            xl="3"
            lg="3"
            sm="6"
            cols="12"
            @click.prevent="onClickItem(item.accountId)"
          >
            <b-media
              no-body
              class="mb-2 mb-xxl-0"
            >
              <b-media-aside
                class="mr-2"
              >
                <b-avatar
                  size="48"
                  :variant="item.color"
                  :class="item.customClass"
                >
                  <feather-icon
                    size="24"
                    :icon="item.icon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ item.title }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ item.subtitle }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  VBTooltip,
  BOverlay, BButton,
} from 'bootstrap-vue'
import { get } from 'vuex-pathify'
import Ripple from 'vue-ripple-directive'
import { formatNumber } from '@/plugins/formaters'

export default {
  components: {
    BButton,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    statistics: {
      type: Object,
    },
    loading: Boolean,
  },
  computed: {
    ...get('appConfig@layout', ['skin']),
    statistics_items() {
      return [
        {
          name: 'active_accounts_in_loss',
          icon: 'BarChart2Icon',
          title: this.statistics.active_accounts_in_loss || '-',
          subtitle: 'Active accounts in loss',
          color: 'info',
          customClass: 'badge-light-danger',
          tooltip: 'Number of active accounts in loss',
        },
        {
          name: 'active_accounts_in_profit',
          icon: 'BarChartIcon',
          title: this.statistics.active_accounts_in_profit || '-',
          subtitle: 'Active accounts in profit',
          color: '',
          customClass: 'badge-light-success',
          tooltip: 'Number of active accounts in profit',
        },
        {
          name: 'max_loss',
          icon: 'DollarSignIcon',
          title: `${formatNumber(this.statistics.max_loss.loss)} %` || '-',
          subtitle: 'Highest loss',
          color: 'info',
          customClass: 'badge-light-danger',
          accountId: this.statistics.max_loss.account_id,
          tooltip: 'Highest loss among active accounts. Click to view account detail.',
        },
        {
          name: 'max_profit',
          icon: 'DollarSignIcon',
          title: `${formatNumber(this.statistics.max_profit.profit)} %` || '-',
          subtitle: 'Highest profit',
          color: '',
          customClass: 'badge-light-success',
          accountId: this.statistics.max_profit.account_id,
          tooltip: 'Highest profit among active accounts. Click to view account detail.',
        },
        {
          name: 'accounts_in_loss',
          icon: 'ArrowDownRightIcon',
          title: this.statistics.accounts_in_loss || '-',
          subtitle: 'Total accounts in loss',
          color: 'info',
          customClass: 'badge-light-danger',
          tooltip: 'Total number of all accounts in loss.',
        },
        {
          name: 'accounts_in_profit',
          icon: 'ArrowUpRightIcon',
          title: this.statistics.accounts_in_profit || '-',
          subtitle: 'Total accounts in profit',
          color: '',
          customClass: 'badge-light-success',
          tooltip: 'Total number of all accounts in profit.',
        },
        {
          name: 'avg_loss',
          icon: 'PercentIcon',
          title: `${formatNumber(this.statistics.avg_loss)} %` || '-',
          subtitle: 'Avg loss',
          color: 'info',
          customClass: 'badge-light-danger',
          tooltip: 'Average loss of active accounts.',
        },
        {
          name: 'avg_profit',
          icon: 'PercentIcon',
          title: `${formatNumber(this.statistics.avg_profit)} %` || '-',
          subtitle: 'Avg profit',
          color: '',
          customClass: 'badge-light-success',
          tooltip: 'Average profit of active accounts.',
        },
      ]
    },
  },
  methods: {
    onClickItem(accountId) {
      this.$router.push({ name: 'account-detail', params: { id: accountId } })
    },
  },
}
</script>
<style scoped lang="scss">

.statistics-body{
  padding-top: 0!important;
}
</style>
