<template>
  <div>
    <b-row class="match-height">
      <b-col lg="12">
        <!-- statistic -->
        <quick-statistics
          :statistics="statistics.quick_statistics"
          :loading="loading"
          @force-reload="getStatisticsData(true)"
        />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col lg="12">
        <!-- active accounts success by phase -->
        <accounts-success-by-phase
          :accounts="statistics.active_accounts"
          :loading="loading"
          @force-reload="getStatisticsData(true)"
        />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        xl="7"
        sm="12"
      >
        <b-row>
          <b-col cols="12">
            <!-- orders balance -->
            <orders-overview
              :show-hour-rate="true"
              :orders="orders"
              @force-reload="getOrders(true)"
            >
              <b-button-toolbar class="mt-1">
                <account-types-switches
                  v-model="types"
                />
                <platforms-switches
                  v-model="platforms"
                />
              </b-button-toolbar>
            </orders-overview>
          </b-col>

          <b-col cols="12">
            <!-- Fail reason analysis -->
            <fail-reason-analysis
              :fail-reasons="statistics.fail_reasons"
              :loading="loading"
              @force-reload="getStatisticsData(true)"
            />
          </b-col>
          <b-col cols="12">
            <!-- Recent completed accounts table -->
            <recent-accounts-table-gql />
          </b-col>
        </b-row>
      </b-col>
      <b-col
        xl="5"
        sm="12"
      >
        <b-row>
          <b-col cols="12">
            <!-- orders data card -->
            <orders-data-card
              :orders="statistics.orders_data"
              :loading="loading"
              @force-reload="getStatisticsData(true)"
            />
          </b-col>
          <b-col cols="12">
            <!-- Funding indicators -->
            <funding-indicators />
          </b-col>
          <b-col cols="12">
            <!-- phases chart -->
            <phase-success-rate />

            <phase3-success-rate
              :accounts-sum="statistics.phases_analysis3"
              :loading="loading"
              :phases="3"
              @force-reload="getStatisticsData(true)"
            />
          </b-col>

          <b-col cols="12">
            <!-- Funded analysis sum profit sum loss -->
            <funded-analysis />
          </b-col>

          <b-col cols="12">
            <!-- Funded analysis sum profit sum loss -->
            <funded-profits-chart-by-tags />
          </b-col>

        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BButtonToolbar } from 'bootstrap-vue'
import OrdersOverview from '@/components/page/orders/OrdersOverview.vue'
import OrdersDataCard from '@/views/home/widgets/OrdersDataCard.vue'
import QuickStatistics from '@/views/home/widgets/QuickStatistics.vue'
import StatisticsAPi from '@/api/statistics'
import PhaseSuccessRate from '@/views/home/widgets/PhaseSuccessRate.vue'
import FailReasonAnalysis from '@/views/home/widgets/FailReasonAnalysis.vue'
import FundedAnalysis from '@/views/home/widgets/FundedAnalysis.vue'
import FundingIndicators from '@/views/home/widgets/FundingIndicators.vue'
import AccountsSuccessByPhase from '@/views/home/widgets/AccountsSuccessByPhase.vue'
import FundedProfitsChartByTags from '@/views/home/widgets/FundedProfitsChartByTags.vue'
import Phase3SuccessRate from '@/views/home/widgets/Phase3SuccessRate.vue'
import AccountTypesSwitches from '@/components/ui/switches/AccountTypesSwitches.vue'
import PlatformsSwitches from '@/components/ui/switches/PlatformsSwitches.vue'
import RecentAccountsTableGql from '@/components/entity/account-gql/RecentAccountsTableGql.vue'

export default {
  components: {
    RecentAccountsTableGql,
    Phase3SuccessRate,
    FundedProfitsChartByTags,
    AccountsSuccessByPhase,
    FundingIndicators,
    FundedAnalysis,
    AccountTypesSwitches,
    PlatformsSwitches,
    BRow,
    BCol,
    BButtonToolbar,
    OrdersOverview,
    OrdersDataCard,
    QuickStatistics,
    FailReasonAnalysis,
    PhaseSuccessRate,
  },
  data() {
    return {
      statistics: {
        active_accounts: [],
        fail_reasons: [],
        orders_data: [],
        phases_analysis3: [],
        quick_statistics: null,
      },
      loading: true,
      ordersLoading: true,
      types: [],
      platforms: [],
      orders: null,
    }
  },
  watch: {
    types() {
      this.getOrders(false)
    },
    platforms() {
      this.getOrders(false)
    },
  },
  beforeMount() {
    this.getStatisticsData(false)
    this.getOrders(false)
  },
  methods: {
    getStatisticsData(force = false) {
      this.loading = true
      StatisticsAPi.getStatistic(force)
        .then(response => {
          this.statistics = response
          this.loading = false
        })
    },
    getOrders(force = false) {
      this.ordersLoading = true
      StatisticsAPi.getOrders(this.types, this.platforms, force)
        .then(response => {
          this.orders = response
          this.ordersLoading = false
        })
    },
  },
}
</script>
