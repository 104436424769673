<template>
  <div>
    <gql-table
      ref="recentAccountsGqlTable"
      title="Recent closed accounts"
      :fields="fields"
      :query="query"
      query-type="accounts"
      sort-by="close_datetime"
      :sort-desc="true"
      :actions="actions"
      @edit="onEdit"
      @aventus="onAventus"
    >
      <template #cell(user)="data">
        <b-row>
          <b-col>
            <b-link :to="{ name: 'user-detail', params: { id: data.item.user.id } }">
              {{ data.item.user.firstName }} {{ data.item.user.lastName }}
              <risk-score-badge
                v-if="data.item.user.risk_score"
                class="mx-1"
                :score-results="data.item.user.risk_score_results"
                :score="data.item.user.risk_score"
              />
              <br>
              <small>{{ data.item.user.email }}</small>
            </b-link>
          </b-col>
          <b-col>
            <tags-list :tags="data.item.user.tags" />
          </b-col>
        </b-row>
      </template>

      <template #cell(account_id)="data">
        <b-row>
          <b-col>
            <b-link :to="{ name: 'account-detail', params: { id: data.item.account_id } }">
              {{ data.item.account_id }} ({{ data.item.server.platform.id }})
            </b-link>
            <risk-score-badge
              v-if="data.item.risk_score"
              class="mx-1"
              :score-results="data.item.risk_score_results"
              :score="data.item.risk_score"
            />
          </b-col>
          <b-col>
            <tags-list
              :tags="data.item.tags"
              :extras-tags="getAccountExtraTags(data.item)"
            />
          </b-col>
        </b-row>
      </template>

      <template #cell(account_type_name)="data">
        {{ accountTypes[data.item.account_type_name] }}
      </template>

      <template #cell(account_subtype_name)="data">
        {{ accountSubtypes[data.item.account_type_name][data.item.account_subtype_name] }}
      </template>

      <template #cell(balance)="data">
        {{ formatCurrency(data.item.balance, data.item.currency.label) }}
      </template>

      <template #cell(deposit)="data">
        {{ formatCurrency(data.item.deposit, data.item.currency.label) }}
      </template>

      <template #cell(close_datetime)="data">
        {{ formatDateTime(data.item.close_datetime) }}
      </template>

      <template #cell(state)="data">
        <b-badge :variant="`light-${stateVariants[data.item.state]}`">
          {{ data.item.state }}
        </b-badge>
      </template>
    </gql-table>
    <b-modal
      id="accountModal"
      title="Edit Account"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <account-form
        module-name="accountsList"
        :account="accountEdit"
        @submit="onEditSubmit()"
      />
    </b-modal>
  </div>
</template>
<script>

import {
  BBadge, BCol, BLink, BModal, BRow,
} from 'bootstrap-vue'
import { dispatch } from 'vuex-pathify'
import Swal from 'sweetalert2'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import GqlTable from '@/components/ui/gqltables/GqlTable.vue'
import RiskScoreBadge from '@/components/ui/RiskScoreBadge.vue'
import TagsList from '@/components/page/TagsList.vue'
import { formatDateTime, formatCurrency } from '@/plugins/formaters'
import {
  ACCOUNTS_SHORT, ACCOUNT_PHASES, STATE_VARIANTS, DEFAULT_FILTER_ACCOUNTS_TYPES, PHASES,
} from '@/enums/accounts'
import { getAccountExtraTags } from '@/plugins/accountUtils'
import AccountForm from '@/components/forms/account/AccountForm.vue'
import AccountAPI from '@/api/account'

const query = [
  'id',
  'account_id',
  'account_type_name',
  'account_subtype_name',
  'balance',
  'deposit',
  'close_datetime',
  'state',
  'risk_score',
  'risk_score_results',
  'phase_move_check',
  'equity_check',
  { currency: { fields: ['label'] } },
  { user: { fields: ['firstName', 'lastName', 'id', 'email', 'risk_score_results', 'risk_score', { tags: { fields: [{ tag: { fields: ['name', 'visible', 'color', 'description'] } }, 'createdAt'] } }] } },
  { tags: { fields: [{ tag: { fields: ['name', 'visible', 'color', 'description'] } }, 'createdAt'] } },
  { server: { fields: ['id', 'name', { platform: { fields: ['id'] } }] } },
]

const actions = [
  {
    text: 'Edit',
    icon: 'EditIcon',
    emit: 'edit',
  },
  {
    text: 'AVENTUS',
    icon: 'RepeatIcon',
    emit: 'aventus',
  },
]
export default {
  components: {
    BModal,
    AccountForm,
    BBadge,
    TagsList,
    BRow,
    BCol,
    RiskScoreBadge,
    BLink,
    GqlTable,
  },
  data() {
    return {
      query,
      actions,
      accountEdit: null,
      accountTypes: ACCOUNTS_SHORT,
      accountSubtypes: ACCOUNT_PHASES,
      stateVariants: STATE_VARIANTS,
      search: '',
      fields: [
        {
          name: 'user',
          label: 'User',
        },
        {
          name: 'account_id',
          label: 'Account number',
        },
        {
          name: 'state', label: 'State', filterable: { type: 'multienum', enum: ['failed', 'completed'] }, default: ['completed'],
        },
        {
          name: 'account_type_name',
          label: 'Account type',
          filterable: { type: 'multienum', enum: Object.keys(ACCOUNTS_SHORT) },
          default: DEFAULT_FILTER_ACCOUNTS_TYPES,
        },
        {
          name: 'account_subtype_name',
          label: 'Phase',
          filterable: { type: 'multienum', enum: Object.keys(PHASES) },
        },
        {
          name: 'deposit',
          label: 'Deposit',
        },
        {
          name: 'balance',
          label: 'Balance',
        },
        {
          name: 'close_datetime',
          label: 'Close date',
          filterable: 'daterange',
          sortable: true,
        },
        {
          name: 'tags',
          label: 'Tags',
          filterable: {
            type: 'multientity', queryType: 'tags', value: 'id', label: 'name', search: 'name',
          },
          visible: false,
        },
        {
          name: 'userTags',
          label: 'User tag',
          filterable: {
            type: 'multientity', queryType: 'tags', value: 'id', label: 'name', search: 'name',
          },
          visible: false,
        },
        {
          name: 'actions',
        },
      ],
    }
  },
  methods: {
    formatDateTime,
    formatCurrency,
    getAccountExtraTags,
    onEdit(item) {
      dispatch('accountsList/get', item.account_id).then(data => {
        this.accountEdit = data.account_data
        this.$bvModal.show('accountModal')
      })
    },
    onEditSubmit() {
      this.$bvModal.hide('accountModal')
    },
    onAventus(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: `Account ${item.account_id} will be checked by AVENTUS`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, run analysis',
      }).then(result => {
        if (result.isConfirmed) {
          AccountAPI.requestAventus(item.id).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Account was add to the front successfully',
                icon: 'WarningIcon',
                text: 'Account is waiting for test now!',
                variant: 'success',
              },
            })
            this.$refs.recentAccountsGqlTable.reloadAll(true)
          })
        }
      })
    },
  },
}
</script>
