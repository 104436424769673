<template>
  <b-form-checkbox-group
    :checked="value"
    :options="optionTypes"
    switches
    @input="e => $emit('input', e)"
  />
</template>
<script>
import { BFormCheckboxGroup } from 'bootstrap-vue'
import { DEFAULT_ACCOUNT_TYPES_SWITCHER } from '@/enums/accounts'

export default {
  components: {
    BFormCheckboxGroup,
  },
  props: {
    value: Array,
    types: {
      type: Array,
      default: () => DEFAULT_ACCOUNT_TYPES_SWITCHER,
    },
  },
  data() {
    return {
      optionTypes: this.types,
    }
  },
}
</script>
