<template>
  <component :is="homepageComponent" />
</template>

<script>
import { get } from 'vuex-pathify'
import Home from './Home.vue'
import UserExternalIndex from '@/views/user/UserExternalIndex.vue'

export default {
  computed: {
    ...get('auth', ['me']),
    homepageComponent() {
      // return this.me.roles.some(item => ['ROLE_ADMIN', 'ROLE_ADMIN_SUPPORT'].includes(item)) ? Home : UserExternalIndex
      return this.me.roles.includes('ROLE_ADMIN_EXTERNAL') ? UserExternalIndex : Home
    },
  },
}
</script>
