<template>
  <b-card
    no-body
  >
    <b-overlay
      :show="!failReasons || loading"
      :variant="skin"
      spinner-variant="primary"
    >
      <b-card-header>
        <b-card-title>Fail reason analysis</b-card-title>
        <b-button
          v-b-tooltip.hover.top="'Force reload statistics'"
          class="float-right"
          variant="outline-primary"
          size="sm"
          @click="$emit('force-reload')"
        >
          <feather-icon icon="RepeatIcon" />
        </b-button>
      </b-card-header>

      <b-card-body
        v-if="failReasons"
        class="position-relative"
      >
        <vue-apex-charts
          type="bar"
          height="250"
          :options="failReasonsChart.chartOptions"
          :series="failReasonsChart.series"
        />
      </b-card-body>

    </b-overlay>
  </b-card>
</template>
<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BOverlay, BButton, VBTooltip,
} from 'bootstrap-vue'
import { get } from 'vuex-pathify'
import VueApexCharts from 'vue-apexcharts'
import _groupBy from 'lodash/groupBy'
import { $themeColors } from '@/../themeConfig'

export default {
  components: {
    BButton,
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    failReasons: Array,
    loading: Boolean,
  },
  computed: {
    ...get('appConfig@layout', ['skin']),
    chartData() {
      const groupByReasons = _groupBy(this.failReasons, result => result.reason ?? 'unspecified')

      const keys = Object.keys(groupByReasons)
      keys.forEach(key => {
        groupByReasons[key] = _groupBy(groupByReasons[key], result => result.account_subtype_name)
      })

      return Object.keys(groupByReasons).map(key => ({
        name: key,
        data: [
          groupByReasons[key].phase1 ? groupByReasons[key].phase1[0]?.count : 0,
          groupByReasons[key].phase2 ? groupByReasons[key].phase2[0]?.count : 0,
          groupByReasons[key].phase3 ? groupByReasons[key].phase3[0]?.count : 0,
          groupByReasons[key].funded ? groupByReasons[key].funded[0]?.count : 0,
        ],
      }))
    },
    failReasonsChart() {
      return {
        series: this.chartData,
        chartOptions: {
          chart: {
            type: 'bar',
          },
          grid: {
            padding: {
              top: -20,
              bottom: -10,
            },
            yaxis: {
              lines: { show: true },
            },
          },
          dataLabels: {
            enabled: true,
            offsetX: -6,
            style: {
              fontSize: '12px',
              colors: ['#fff'],
            },
          },
          xaxis: {
            categories: ['Phase1', 'Phase2', 'Phase3', 'Funded'],
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          plotOptions: {
            bar: {
              horizontal: true,
              dataLabels: {
                position: 'top',
              },
            },
          },
          colors: [$themeColors.danger],
          fill: {
            opacity: 0.5,
            colors: [$themeColors.danger],
          },
          stroke: {
            show: true,
            colors: [$themeColors.primary, $themeColors.info],
            width: 1,
          },
          yaxis: {
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1.5rem',
              },
            },
          },
          tooltip: {
            shared: true,
            intersect: false,
          },
        },
      }
    },
  },
}
</script>
