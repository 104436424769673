<template>
  <b-card
    no-body
  >
    <b-overlay
      :show="loading"
      :variant="skin"
      spinner-variant="primary"
    >
      <b-card-header>
        <b-card-title>
          Funded analysis
          <b-badge
            class="m-25"
            size="sm"
            variant="light-primary"
          >
            <strong>Active funded: </strong> {{ fundedCount }}
          </b-badge>
          <br>
          <small>Sum of profit/loss of each currency from funded accounts</small>
        </b-card-title>

        <b-button-toolbar>
          <account-types-switches
            v-model="types"
            class="my-50"
          />
          <platforms-switches
            v-model="platforms"
            class="my-50"
          />
        </b-button-toolbar>
      </b-card-header>

      <b-card-body
        v-if="analyticsData"
        class="position-relative"
      >
        <vue-apex-charts
          type="bar"
          height="100"
          :options="generateChartConfig(themeColors.success)"
          :series="profitAccounts"
        />
        <vue-apex-charts
          type="bar"
          height="100"
          :options="generateChartConfig(themeColors.danger)"
          :series="lossAccounts"
        />
      </b-card-body>
    </b-overlay>
  </b-card>
</template>
<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BOverlay, BButtonToolbar, BBadge,
} from 'bootstrap-vue'
import { get } from 'vuex-pathify'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@/../themeConfig'
import { formatNumber } from '@/plugins/formaters'
import StatisticsAPI from '@/api/statistics'
import AccountTypesSwitches from '@/components/ui/switches/AccountTypesSwitches.vue'
import PlatformsSwitches from '@/components/ui/switches/PlatformsSwitches.vue'
import { DEFAULT_PLATFORMS_SWITCHER, DEFAULT_ACCOUNT_TYPES_SWITCHER } from '@/enums/accounts'

export default {
  components: {
    AccountTypesSwitches,
    PlatformsSwitches,
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BOverlay,
    BButtonToolbar,
    BBadge,
  },
  data() {
    return {
      types: DEFAULT_ACCOUNT_TYPES_SWITCHER.map(item => item.value),
      platforms: DEFAULT_PLATFORMS_SWITCHER,
      loading: true,
      analyticsData: null,
      fundedCount: null,
    }
  },
  computed: {
    ...get('appConfig@layout', ['skin']),
    themeColors() {
      return $themeColors
    },
    lossAccounts() {
      const acc = []
      this.analyticsData.loss_amounts.forEach(item => {
        acc.push(
          {
            y: item.amount,
            x: item.label,
          },
        )
      })

      return [{
        name: 'Loss',
        data: acc,
      }]
    },
    profitAccounts() {
      const acc = []
      this.analyticsData.profit_amounts.forEach(item => {
        acc.push(
          {
            y: item.amount,
            x: item.label,
          },
        )
      })

      return [{
        name: 'Profit',
        data: acc,
      }]
    },
  },
  watch: {
    types() {
      this.reloadData()
    },
    platforms() {
      this.reloadData()
    },
  },
  beforeMount() {
    this.reloadData()
  },
  methods: {
    reloadData() {
      this.loading = true
      StatisticsAPI.getFundedAnalysis(this.types, this.platforms)
        .then(response => {
          this.analyticsData = response.funded_analysis
          this.fundedCount = response.funded_count
          this.loading = false
        })
    },
    generateChartConfig(color) {
      return {
        chart: {
          type: 'bar',
        },
        grid: {
          padding: {
            top: -20,
            bottom: -10,
          },
          yaxis: {
            lines: { show: true },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: 30,
          style: {
            fontSize: '12px',
            colors: ['#fff'],
          },
          formatter: value => formatNumber(value),
        },
        xaxis: {
          type: 'category',
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '1rem',
            },
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
        legend: {
          show: false,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: 'top',
            },
          },
        },
        colors: [color],
        fill: {
          opacity: 0.5,
          colors: [color],
        },
        stroke: {
          show: true,
          colors: [$themeColors.primary, $themeColors.info],
          width: 1,
        },
        yaxis: {
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '1.5rem',
            },
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
      }
    },
  },
}
</script>
